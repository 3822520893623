import { useEffect, useState } from "react";
import Button from "@/components/Button";
import Table from "@/components/Table";
import NavLayout from "@/components/NavLayout";
import { useDeleteSubmissionCollection } from "@/hooks/submission";
import { useSubmissionCollection } from "@/hooks/submission";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import SubmissionSettingsSection from "@/sections/submission/TemplateSettingsSection";
import Spinner from "@/components/Spinner";
function SubmissionPage() {
  const animationParent = useAutoAnimate({});
  const { submissions, isLoading } = useSubmissionCollection();
  const { deleteSubmissionCollection } = useDeleteSubmissionCollection();

  const [selected, setSelected] = useState<string[]>();
  const [current, setCurrent] = useState<string>();

  useEffect(() => {
    console.log("selected");
    console.log(selected);
    console.log("current");
    console.log(current);
  }, [selected, current]);

  const structured = submissions
    ?.map((data: any) => {
      return {
        id: data.id,
        template: data.expand.template?.name ?? "-",
        name: data.name,
        user: data.expand.user.displayName,
        email: data.expand.user.email,
        // size: data.size,
        created: data.created,
      };
    })
    .sort((a, b) => Date.parse(b.created) - Date.parse(a.created));
  const myAnimationParent = animationParent as any;

  if (isLoading) {
    return (
      <NavLayout>
        <div className="w-full h-full min-h-[500px] flex justify-center items-center">
          <Spinner/>
        </div>
      </NavLayout>
    );
  }

  return (
    <div>
      <NavLayout
        overlayChildren={
          <SubmissionSettingsSection
            data={
              (submissions?.filter((x) => x.id == current) ?? [
                { name: "undefined" },
              ])[0]
            }
          />
        }
      >
        <div ref={myAnimationParent} className="flex flex-col p-8 gap-y-4">
          <h1 className="text-2xl font-roboto">Submissions</h1>
          {(selected ?? []).length > 0 ? (
            <div className="flex flex-row justify-between gap-x-2 w-full">
              <label htmlFor="modal-delete-submissions" className="">
                <Button
                  className="w-full pointer-events-none text-2xs md:text-xs md:w-40"
                  appearance="destructive"
                >
                  Delete Submission{(selected ?? []).length > 1 ? "s" : ""}
                </Button>
              </label>
            </div>
          ) : (
            <></>
          )}
          {structured?.length == 0 ? (
            <div className="text-center pt-10">
              <i>Start uploading to see your submissions.</i>
            </div>
          ) : (
            <Table
              dataSource={structured ?? []}
              mobileVisibleKeys={["name", "created"]}
              hidePrimaryKey={true}
              primaryKey="id"
              hideOptions
              hideCheckbox={false}
              setCheckboxSelected={setSelected}
              checkboxSelected={selected}
              onItemClick={(id: string) => {
                const link = document.getElementById("my-link");
                if (link == null) {
                  return;
                }
                setCurrent(id);
                link!.click();
              }}
            />
          )}
          <label
            id="my-link"
            htmlFor="my-drawer-4"
            className="drawer-button btn btn-info hidden"
          >
            Open drawer
          </label>
        </div>
      </NavLayout>
      <input
        type="checkbox"
        id="modal-delete-submissions"
        className="modal-toggle"
      />
      <label
        htmlFor="modal-delete-submissions"
        className="modal cursor-pointer modal-bottom sm:modal-middle"
      >
        <label className="modal-box relative" htmlFor="">
          <h3 className="text-lg font-bold text-base-content">
            Are you sure you want to delete these submissionss?
          </h3>
          <p className="py-4">
            The action you are about to perform is irreversible and these
            submissions will be permenatly deleted.
          </p>
          <div className="flex flex-row justify-between">
            <div className="modal-action">
              <label
                htmlFor="modal-delete-submissions"
                className=""
                onClick={() => {
                  setSelected([]);
                  deleteSubmissionCollection(selected ?? []);
                }}
              >
                <Button
                  className="w-full pointer-events-none text-2xs"
                  appearance="destructive"
                >
                  Delete
                </Button>
              </label>
            </div>
            <div className="modal-action w-full">
              <label htmlFor="modal-delete-submissions" className="">
                <Button
                  className="w-full pointer-events-none text-2xs"
                  appearance="secondary"
                >
                  Cancel
                </Button>
              </label>
            </div>
          </div>
        </label>
      </label>
    </div>
  );
}

export default SubmissionPage;

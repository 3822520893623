import { pb } from "@/lib/pocketbase";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const SUBMISSION_QUERY_KEY = 'submission';

export const getSubmissionCollection = () => {
  try {
    return pb.collection('submission').getFullList(undefined, {
      expand: 'user, template'
  });
  } catch (err) {
    return undefined;
  }
};

export const deleteSubmission = async (id:string[]) => {
  await Promise.all((id ?? []).map(async (id) => {
    await pb.collection('submission').delete(id);
  }));
  
}

export function useDeleteSubmissionCollection() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (args: any) =>
      await deleteSubmission(args),
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries([SUBMISSION_QUERY_KEY]);
      },
    },
  );
  return {
    deleteSubmissionCollection: async (args: any) => {
      try {
        await mutation.mutateAsync(args);
        return true;
      } catch (err) {
        return false;
      }
    },
    deleteSubmissionCollectionError: mutation.isError,
    deleteSubmissionCollectionLoading: mutation.isLoading,
  };
}

export function useSubmissionCollection() {
  const { isLoading, isError, data, error } = useQuery(
    [SUBMISSION_QUERY_KEY],
    () => getSubmissionCollection(),
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      // staleTime: 30_000, // 3s
    },
  );

  return {
    submissions: data,
    isError,
    isLoading,
    error: error as Error,
  };
}
export const getFile = async (
  collectionName: string,
  rowId: string,
  fileName: string,
) => {
  const POCKETBASE_URL = process.env.REACT_APP_API_URL || '';
  try {
    const response = await fetch(
      `${POCKETBASE_URL}/api/files/${collectionName}/${rowId}/${fileName}`,
      {
        method: 'GET',
      },
    );
    const blob = await response.blob();
    return blob
  } catch (error) {
    //MARK: Insert Logging
    console.log(error);
  }
};
export const getSubmissionFile = ({id, fileName}:{id: string, fileName: string}) => {
  try {
    return getFile('submission', id, fileName);
  } catch (err) {
    return undefined;
  }
};

export function useSubmissionFile({id, fileName}: {id: string, fileName: string}) {
  const { isLoading, isError, data, error } = useQuery(
    [SUBMISSION_QUERY_KEY],
    () => getSubmissionFile({id: id, fileName: fileName}),
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      // staleTime: 30_000, // 3s
    },
  );

  return {
    submission: data,
    isError,
    isLoading,
    error: error as Error,
  };
}
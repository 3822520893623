import React from 'react'
import { Button as ButtonType } from './types';
import styled from './styled';

function Button({children, className, appearance, ...props}: ButtonType) {
  return (
	  <button className={`btn normal-case font-normal rounded-xl ${className} ${styled(appearance)}`} {...props}>{children}</button>
  )
}

export default Button;
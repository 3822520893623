import React, { ReactNode } from "react";
import NavSideBar from "../NavSideBar";
import NavTopBar from "../NavTopBar";

const NavLayout = ({
  children,
  overlayChildren,
}: {
  children?: ReactNode;
  overlayChildren?: ReactNode;
}) => {
  return (
    <div>
      <div className="drawer drawer-mobile">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col">
          {/* <!-- Page content here --> */}
          <div className="drawer drawer-end">
            <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content">
              <NavTopBar />
              {children}
            </div>
            <div className="drawer-side">
              <label htmlFor="my-drawer-4" className="drawer-overlay"></label>
              <div className="menu p-4 w-80 bg-base-100 text-slate-900">
                {overlayChildren}
              </div>
            </div>
          </div>
        </div>
        <div className="drawer-side">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <ul className="menu p-4 overflow-y-auto w-80 bg-base-100">
            {/* <!-- Sidebar content here --> */}
            <NavSideBar />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavLayout;

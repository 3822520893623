import React from "react";
import { ReactComponent as MenuIcon } from "../../assets/images/MenuIcon.svg";
import { ReactComponent as Sign365Icon } from "../../assets/images/Sign365Icon.svg";

function NavTopBar() {
  return (
    <div className="h-16 flex flex-row justify-between items-center px-5">
      <label htmlFor="my-drawer-2" className="drawer-button lg:hidden">
        <MenuIcon className="h-12" />
      </label>
      <div>
        <Sign365Icon className="lg:opacity-0 h-8" />
      </div>
      <div className={`h-9 w-9 rounded-full bg-slate-600 opacity-0`}></div>
    </div>
  );
}

export default NavTopBar;

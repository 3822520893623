import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import colors from "@/utils/colors";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "60px",
  borderWidth: 2,
  borderRadius: 10,
  borderColor: colors["slate-200"],
  borderStyle: "dashed",
  color: colors["slate-200"],
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: colors["black"],
};

const acceptStyle = {
  background: colors["action-light"],
};

const rejectStyle = {
  background: colors["destruction-dark"],
};

export function StyledDropzone(props: any) {
  const [text, setText] = useState("Press to upload PDF files");
  const [bg, setBg] = useState(colors["slate-400"]);

  const onDrop = async (acceptedFiles: File[]) => {
    if ((await props.onDrop(acceptedFiles)) == undefined) {
      setText("Some files failed to upload (check file name doesn't already exist)");
      setBg(colors["destruction-dark"]);
      return;
    }
    setText(`${acceptedFiles.length} Templates Uploaded`);
    setBg(colors["action-light"]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ accept: { "application/pdf": [".pdf"] }, onDrop: onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...{ backgroundColor: bg },
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, bg]
  );

  return (
    <div className="container capitalize font-bold">
      {/* 
	//@ts-ignore */}
      <div className={`bg-[${bg}]`} {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{text}</p>
      </div>
    </div>
  );
}

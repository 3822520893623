import React, { ReactNode } from "react";

export interface DashboardTileProps {
  title: string,
  onClick?: () => void,
  children?: ReactNode,
  colspan?: "1" | "2" | "3"  
}

const DashboardTile = ({
  title,
  onClick,
  children,
  colspan,
}: DashboardTileProps) => {

  const conditions = {
    '1': 'lg:col-span-1 md:col-span-1 sm:col-span-1',
    '2': 'lg:col-span-2 md:col-span-2 sm:col-span-1',
    '3': 'lg:col-span-3 md:col-span-2 sm:col-span-1',
  };
  
  const col = !colspan ? "" : conditions[colspan]

  return (
      <div
        onClick={onClick}
        className={"flex flex-col select-none p-9 bg-slate-200 w-full rounded-lg cursor-pointer " + col}
      >
        <p className="text-xl font-bold select-none capitalize tracking-tighter mb-5">
          {title}
        </p>

        <div className="flex justify-center items-center w-full h-[95%]">
          {children}
        </div>
      </div>
  );
};

export default DashboardTile;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Button from "@/components/Button";
import Table from "@/components/Table";
import { useUserCollection } from "@/hooks/user";
import useWindowDimensions from "@/hooks/windowDimentions";
import { Record } from "pocketbase";
import { useUpdateIntegrationCollection } from "@/hooks/integration";
import { useNavigate } from "react-router-dom";
import Input from "@/components/Input";
import { useForm } from "react-hook-form";
import Spinner from "@/components/Spinner";

function WorkforceOneManageSection({ integration }: { integration: Record }) {
  const { users, isLoading, isError } = useUserCollection();
  const { width } = useWindowDimensions();
  const { updateIntegrationCollection, updateIntegrationCollectionLoading } =
    useUpdateIntegrationCollection();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const [current, setCurrent] = useState<string>();
  const [currentUser, setCurrentUser] = useState<string>();

  useEffect(() => {
    if (integration.settings.fallbackEmail != undefined) {
      setValue("email", integration.settings.fallbackEmail);
    }
  }, [integration]);

  useEffect(() => {
    const link = document.getElementById("my-link");
    if (link == null) {
      return;
    }
    link!.click();
  }, [current]);

  const onSubmit = async (formData: any) => {
    const newSettings = JSON.parse(JSON.stringify(integration.settings));
    newSettings.fallbackEmail = formData.email;
    const success = await updateIntegrationCollection({
      id: integration.id,
      isSetup: true,
      settings: newSettings,
    });
    if (success) {
      navigate(`/integrations/${integration.id}`);
    }
  };

  const structuredUsers = users?.map((user: any) => {
    return {
      id: user.id,
      displayName: user.displayName,
      email: user.email,
      // configured: `false`
      externalId:
        integration.settings.users == undefined
          ? ""
          : `${
              integration.settings.users.filter((x: any) => x.id == user.id)
                .length > 0
                ? integration.settings.users.filter(
                    (x: any) => x.id == user.id
                  )[0].externalId
                : ""
            }`,
      configured:
        integration.settings.users == undefined
          ? "N"
          : `${
              integration.settings.users.filter((x: any) => x.id == user.id)
                .length > 0
                ? "Y"
                : "N"
            }`,
    };
  });

  if (isLoading) {
    return <></>;
  }
  if (isError) {
    return <>isError...</>;
  }

  const DropDown = () => {
    const [externalId, setExternalId] = useState<string>('');

    useEffect(() => {
      const settings = JSON.parse(JSON.stringify(integration.settings));
      const externalId_ = !settings.users ? "" : 
                          !settings.users.find((x: any) => x.id == currentUser) ? "" :
                          settings.users.find((x: any) => x.id == currentUser).externalId
      setExternalId(externalId_)
    }, [currentUser])

    const handleInputChange = (event: any) => {
      event.preventDefault();
      setExternalId((event.target.value as string).replace(/\D/g, ''));
    };

    const handleSet = async () => {
      if (!externalId) {
        return;
      }
      const settings = JSON.parse(JSON.stringify(integration.settings));
      if (!settings.users) {
        settings.users = [
          {
            id: currentUser,
            externalId: externalId,
          },
        ];
      } else {
        const userIndex = settings.users.findIndex((x: any) => x.id === currentUser);
        if (userIndex === -1) {
          // User not found, add a new user to the array
          settings.users.push({ id: currentUser, externalId: externalId });
        } else {
          // User found, update the externalId
          settings.users[userIndex].externalId = externalId;
        }
      }
      const result = { id: integration.id, settings: settings };
      console.log("result");
      console.log(result);

      const success = await updateIntegrationCollection({
        id: integration.id,
        settings: settings,
      });
      if (success) {
      }
    };

    return (
      <label className="modal-box relative w-full overflow-visible" htmlFor="">
        <div className="flex flex-row justify-between items-center mb-4">
          <h3 className="text-lg font-bold text-base-content">
            Please Select WorkforceOne Id
          </h3>
          <div className="flex flex-row w-[30%] justify-end items-center">
        </div>
        </div>
        <div className={`dropdown ${width < 640 ? "dropdown-top" : ""} w-full`}>
          <input 
            className="btn m-1 w-full bg-slate-200 text-base-content capitalize"
            type="text"
            value={externalId} // Bind value to state variable
            onChange={handleInputChange} // Handle input change event
          />
        </div>
        <div className="flex flex-row justify-between">
          <div className="modal-action">
            <label
              onClick={handleSet}
              htmlFor="modal-set-integration"
              className=""
            >
              <Button
                className="w-20 pointer-events-none text-2xs"
                appearance="accent"
              >
                Set
              </Button>
            </label>
          </div>
          <div className="modal-action w-full">
            <label htmlFor="modal-set-integration" className="">
              <Button
                className="w-20 pointer-events-none text-2xs"
                appearance="secondary"
              >
                Cancel
              </Button>
            </label>
          </div>
        </div>
      </label>
    );
  };

  return (
    <>
      <div className="flex flex-col p-8 gap-y-4 h-full">
        <h1 className="text-2xl font-roboto">{integration.name}</h1>
        <p>These are the setting for your Workforce One connector.</p>
        <div>
          <Table
            dataSource={structuredUsers ?? []}
            mobileVisibleKeys={["displayName", "email", "configured"]}
            hidePrimaryKey={true}
            primaryKey="id"
            hideOptions
            onItemClick={(id: string) => {
              setCurrent((prev) => prev + id);
              setCurrentUser(id);
            }}
          />
        </div>
        <h1 className="text-2xl font-roboto pt-4">Fallback Email</h1>
        <p>
          Please enter an email that you would like to send to in the event the
          connector doesn't connect.
        </p>
        <form
          className="flex flex-col gap-y-4 h-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            type="email"
            name="email"
            label="Email Address"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Email is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
          <button className="btn btn-info w-28 sm:my-0" type="submit">
            <p className="pr-2">Save</p>
            {updateIntegrationCollectionLoading && <Spinner />}
          </button>
        </form>
      </div>
      <label
        id="my-link"
        htmlFor="modal-set-integration"
        className="hidden"
      ></label>
      <input
        type="checkbox"
        id="modal-set-integration"
        className="modal-toggle"
      />
      <label
        htmlFor="modal-set-integration"
        className="modal cursor-pointer modal-bottom sm:modal-middle overflow-visible"
      >
        <DropDown />
      </label>
    </>
  );
}

export default WorkforceOneManageSection;

import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { pb } from '@/lib/pocketbase';
import { useNavigate } from "react-router-dom";

const useLoginPage = () => {
	const navigate = useNavigate();
	const [loginFailed, setLoginFailed] = useState(false);
	const [isRequesting, setIsRequesting] = useState(false);
	const {
	  register,
	  handleSubmit,
	  reset,
	  formState: { errors },
	} = useForm();

	const login = async (username:string, password:string) => {
		console.log(username, password)
		await pb.collection('user').authWithPassword(username, password);
	}

	const onSubmit = async (data:any) => {
		setLoginFailed(false)
		setIsRequesting(true)
		
		try {
			await login(data.username, data.password);
			navigate("/");
		} catch (error) {
			console.log('failed')
			setLoginFailed(true)
		}
		
		setIsRequesting(false)
		reset();
	};

	return {
		loginFailed,
		isRequesting,
		errors,
		register,
		handleSubmit,
		onSubmit
	}

}

export default useLoginPage
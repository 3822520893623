const Input = ({
  name,
  label,
  register,
  errors,
  required,
  value,
  type,
  validationSchema,
  classname,
}: any) => (
  <div className={`form-control-input flex flex-col ${classname}`}>
    <label className="text-sm pb-1 text-base-content" htmlFor={name}>
      {label}
    </label>
    <input
      className="input bg-transparent rounded-lg text-base-content border border-slate-500"
      id={name}
      name={name}
      type={type}
      value={value}
      {...register(name, validationSchema)}
    />
    {errors && errors[name]?.type === "required" && (
      <span className="error mt-2 text-xs text-errorRed pl-2">
        {errors[name]?.message}
      </span>
    )}
    {errors && errors[name]?.type === "minLength" && (
      <span className="error mt-2 text-xs text-errorRed pl-2">
        {errors[name]?.message}
      </span>
    )}
    {errors && errors[name]?.type === "pattern" && (
      <span className="error mt-2 text-xs text-errorRed pl-2">
        {errors[name]?.message}
      </span>
    )}
  </div>
);
export default Input;

import { pb } from "@/lib/pocketbase";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const TEMPLATES_QUERY_KEY = 'template';

export const getTemplateCollection = async () => {
  try {
    return await pb.collection('template').getFullList()
  } catch (err) {
    return undefined;
  }
};

export const deleteTemplate = async (id:string[]) => {
  await Promise.all((id ?? []).map(async (id) => {
    await pb.collection('template').delete(id);
  }));
  
}
export const updateTemplate = async (args:any) => {
  console.log('args');
  console.log(args);
  
  const data = {
    "settings": args.settings
  };
  await Promise.all((args.id ?? []).map(async (id:string) => {
    await pb.collection('template').update(id, data);
  }));
  
}

export function useCreateTemplateCollection() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (args: any) =>
      await pb.collection('template').create(args),
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries([TEMPLATES_QUERY_KEY]);
      },
    },
  );
  return {
    createTemplateCollection: async (args: any) => {
      try {
        await mutation.mutateAsync(args);
        return true;
      } catch (err) {
        return false;
      }
    },
    createTemplateCollectionError: mutation.isError,
    createTemplateCollectionLoading: mutation.isLoading,
  };
}

export function useDeleteTemplateCollection() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (args: any) =>
      await deleteTemplate(args),
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries([TEMPLATES_QUERY_KEY]);
      },
    },
  );
  return {
    deleteTemplateCollection: async (args: any) => {
      try {
        await mutation.mutateAsync(args);
        return true;
      } catch (err) {
        return false;
      }
    },
    deleteTemplateCollectionError: mutation.isError,
    deleteTemplateCollectionLoading: mutation.isLoading,
  };
}
export function useUpdateTemplateCollection() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (args: any) =>
      await updateTemplate(args),
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries([TEMPLATES_QUERY_KEY]);
      },
    },
  );
  return {
    updateTemplateCollection: async (args: any) => {
      try {
        await mutation.mutateAsync(args);
        return true;
      } catch (err) {
        return false;
      }
    },
    updateTemplateCollectionError: mutation.isError,
    updateTemplateCollectionLoading: mutation.isLoading,
  };
}

export function useTemplateCollection() {
  const { isLoading, isError, data, error } = useQuery(
    [TEMPLATES_QUERY_KEY],
    () => getTemplateCollection(),
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: true,
      // staleTime: 30_000, // 3s
    },
  );

  return {
    templates: data,
    deleteTemplate,
    isError,
    isLoading,
    error: error as Error,
  };
}
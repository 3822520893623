import { useEffect, useState } from 'react';
import { DataSourceProps } from './types';

function useTable(props: DataSourceProps) {
	const {dataSource, mobileVisibleKeys, primaryKey, hideOptions, hidePrimaryKey} = props;
	const keys =  Object.keys(dataSource.length > 0 ? dataSource[0] : []);
	const values = dataSource.map(item => Object.values(item));

	const [actionSnackbarKey, setActionSnackbarKey] = useState(-1);
	const [actionSnackbarMessage, setActionSnackbarMessage] = useState('');
	const [mobileVisibleIndices, setMobileVisibleIndices] = useState<any>([]);
	const [primaryKeyIndex, setPrimaryKeyIndex] = useState<any>(0);
	const [optionsIndex, setOptionsIndex] = useState<any>(-1);

	useEffect(() => {
		const tableBuilder = (() => {
			var mvisibleIndices: string[] = [];
			var primaryKeyIndex: number = -1;
			var optionsIndex: number = -1;
			if (mobileVisibleKeys === undefined) {
				return [[],0,-1]
			} else {
				for (const key in keys) {
					if (Object.prototype.hasOwnProperty.call(keys, key)) {
						const element = keys[key];
						for (const mobileKey of mobileVisibleKeys) {
							if (mobileKey == element) {
								mvisibleIndices.push(key);
							}
						}
						if (primaryKey === element) {
							primaryKeyIndex = key as unknown as number;
						}
						if ('options' === element) {
							optionsIndex = key as unknown as number
						}
					}
				}
			}
			return [mvisibleIndices, primaryKeyIndex, optionsIndex];
		})()
		setMobileVisibleIndices(tableBuilder[0]);
		setPrimaryKeyIndex(tableBuilder[1]);
		setOptionsIndex(tableBuilder[2])
	}, [])


	const filteredKeys = keys;

	return {
		primaryKey,
		filteredKeys,
		values,
		mobileVisibleIndices,
		actionSnackbarKey,
		setActionSnackbarKey,
		actionSnackbarMessage,
		setActionSnackbarMessage,
		primaryKeyIndex,
		setPrimaryKeyIndex,
		optionsIndex,
		setOptionsIndex,
		hidePrimaryKey,
		hideOptions
	}
}

export default useTable
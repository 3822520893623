import React from "react";
import { ReactComponent as Sign365Icon } from "../../assets/images/Sign365Icon.svg";
import { Link } from "react-router-dom";
import {
  DashboardIcon,
  UsersIcon,
  TemplatesIcon,
  ReportsIcon,
  IntegrationsIcon,
} from "../Icons";
import { useNavigate } from "react-router-dom";
import { currentUser, pb } from "@/lib/pocketbase";
import colors from "@/utils/colors";

const NavSideBar = () => {
  const navigate = useNavigate();
  const logout = () => {
    sessionStorage.clear();
    currentUser.value = null;
    pb.authStore.clear();
    navigate("/login");
    window.location.reload();
  };
  return (
    <div>
      <Sign365Icon className="hidden lg:block mb-8 mt-6 w-32 ml-6" />
      <li className="">
        <Link to="/">
          <DashboardIcon color={colors["slate-800"]} height={20} width={20} />
          Dashboard
        </Link>
      </li>

      <li className="">
        <Link to="/users">
          <UsersIcon color={colors["slate-800"]} height={20} width={20} />
          Users
        </Link>
      </li>

      <li className="">
        <Link to="/templates">
          <TemplatesIcon color={colors["slate-800"]} height={20} width={20} />
          Templates
        </Link>
      </li>

      <li className="">
        <Link to="/submissions">
          <TemplatesIcon color={colors["slate-800"]} height={20} width={20} />
          Submissions
        </Link>
      </li>

      <li className="">
        <Link to="/reports">
          <ReportsIcon color={colors["slate-800"]} height={20} width={20} />
          Reports
        </Link>
      </li>

      <li className="">
        <Link to="/integrations">
          <IntegrationsIcon
            color={colors["slate-800"]}
            height={20}
            width={20}
          />
          Integrations
        </Link>
      </li>
      <li className="menu-title">
        <span>Documentation</span>
      </li>
      <li>
        <Link to="/documentation">Admin Center</Link>
      </li>
      <li>
        <Link to="/documentation">App (iOS)</Link>
      </li>
      <li className="menu-title">
        <span>Company</span>
      </li>
      <li>
        <Link to="/privacy">Privacy Policy</Link>
      </li>
      <li>
        <Link to="/t&c">Terms and Conditions</Link>
      </li>
      <li className="mt-auto w-[60%] pl-4 mt-4">
        <button
          onClick={() => logout()}
          className="btn bg-gradient-to-r from-warning to-error border-0 font-arimo font-normal capitalize"
          style={{ borderRadius: "12rem" }}
        >
          Logout
        </button>
      </li>
    </div>
  );
};

export default NavSideBar;

import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import React, { ReactNode, useEffect, useState } from "react";
import UserPage from "./pages/UserPage";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { pb } from "./lib/pocketbase";
import TemplatePage from "./pages/TemplatePage";
import UserManagePage from "./pages/UserManagePage";
import ReportPage from "./pages/ReportPage";
import IntegrationPage from "./pages/IntegrationPage";
import IntegrationManagePage from "./pages/IntegrationManagePage";
import IntegrationSetupServicePage from "./pages/IntegrationSetupServicePage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import DocumentationPage from "./pages/DocumentationPage";
import SignupPage from "./pages/SignupPage";
import RedirectPage from "./pages/RedirectPage";
import SubmissionPage from "./pages/SubmissionPage";

function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [isLoggedIn, setIsLoggedIn] = useState(pb.authStore.isValid);

  // Listen to changes in the auth store
  useEffect(() => {
    const removeListener = pb.authStore.onChange(() => {
      setIsLoggedIn(pb.authStore.isValid);
    });
    return () => {
      // Clean up the listener when the component unmounts
      removeListener();
    };
  }, []);
  
  const isLogged = (node: ReactNode): ReactNode => {
    return isLoggedIn ? node : <Navigate replace to={"/login"} />;
  };

  const isLoginLogged = (node: ReactNode): ReactNode => {
    return isLoggedIn ? <Navigate replace to={"/"} /> : node;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={isLogged(<DashboardPage />)} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/users" element={isLogged(<UserPage />)} />
        <Route path="/users/:id" element={isLogged(<UserManagePage />)} />
        <Route path="/templates" element={isLogged(<TemplatePage />)} />
        <Route path="/submissions" element={isLogged(<SubmissionPage />)} />
        <Route path="/reports" element={isLogged(<ReportPage />)} />
        <Route path="/integrations" element={isLogged(<IntegrationPage />)} />
        <Route
          path="/integrations/:id"
          element={isLogged(<IntegrationManagePage />)}
        />
        <Route
          path="/integrations/:id/setup"
          element={isLogged(<IntegrationSetupServicePage />)}
        />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/t&c" element={<TermsAndConditionsPage />} />
        <Route path="/documentation" element={<DocumentationPage />} />
        <Route path="/redirect" element={<RedirectPage />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;

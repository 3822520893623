import Input from "@/components/Input";
import Spinner from "@/components/Spinner";
import { useUpdateTemplateCollection } from "@/hooks/template";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

function TemplateSettingsSection({ data }: { data: any }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (data) {
      setValue(
        "email",
        (data ?? { settings: undefined }).settings != undefined
          ? data.settings.email
          : ""
      );
      setValue(
        "enabled",
        (data ?? { settings: undefined }).settings != undefined
          ? data.settings.enabled
          : false
      );
    }
  }, [data]);

  const { updateTemplateCollection, updateTemplateCollectionLoading } =
    useUpdateTemplateCollection();

  if (data == undefined) {
    return (
      <div className="w-full h-full items-center justify-center">
        <span className="loading loading-bars loading-xs"></span>
      </div>
    );
  }

  const onSubmit = async (formData: any) => {
    const mydata = {
      email: formData.email,
      enabled: formData.enabled,
    };

    await updateTemplateCollection({ id: [data.id], settings: mydata });
  };

  return (
    <div className="pt-20 px-4 flex flex-col h-full">
      <h1 className="text-2xl pb-2">Template Settings</h1>
      <p className="font-light text-sm pb-6">
        Please review the settings for your template ‘{data.name}’.
      </p>
      <form
        className="flex flex-col gap-y-4 h-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="email"
          name="email"
          label="Email Address"
          errors={errors}
          register={register}
          validationSchema={{
            required: "Email is required",
            minLength: {
              value: 3,
              message: "Please enter a minimum of 3 characters",
            },
          }}
          required
        />
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text">Enable Rule</span>
            <input
              type="checkbox"
              id="enabled"
              {...register("enabled")}
              className={`form-check-input ${
                errors.enabled ? "is-invalid" : ""
              }`}
            />
          </label>
        </div>
        <button
          className="btn btn-info capitalize font-normal mt-auto"
          type="submit"
        >
          <p className="pr-2">Save</p>
          {updateTemplateCollectionLoading && <Spinner />}
        </button>
      </form>
    </div>
  );
}

export default TemplateSettingsSection;

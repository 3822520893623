import NavLayout from "@/components/NavLayout";
import { useIntegrationCollection } from "@/hooks/integration";
import WorkforceOneSetupServiceSection from "@/sections/integration/WorkforceOneSetupServiceSection";
import WorkforceOneSetupUsersSection from "@/sections/integration/WorkforceOneSetupUsersSection";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
function IntegrationSetupServicePage() {
  const { id } = useParams();
  const { integrations, isLoading } = useIntegrationCollection();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  //Redirects if integration is not setup
  useEffect(() => {
    if (current != undefined) {
      if (current!.isSetup) {
        navigate(`/integrations/${current!.id}`);
      }
    }
  }, [integrations]);

  //Selected integration based on route
  const current = integrations?.filter(
    (integration) => integration.id == id
  )[0];

  //Section Navigation
  const nextPage = () => {
    setPage(page + 1);
  };

  if (isLoading) {
    return (
      <div className="w-full h-full items-center justify-center">
        <span className="loading loading-bars loading-xs"></span>
      </div>
    );
  }

  //Workforce One Configuration
  if (current!.type == "Workforce One Service") {
    return (
      <div>
        <NavLayout>
          {page == 0 && (
            <WorkforceOneSetupServiceSection
              integration={current!}
              next={nextPage}
            />
          )}
          {page == 1 && (
            <WorkforceOneSetupUsersSection integration={current!} />
          )}
        </NavLayout>
      </div>
    );
  }

  return (
    <div>
      <p>An unknown error has occurred</p>
    </div>
  );
}

export default IntegrationSetupServicePage;

import { useEffect, useState } from "react";
import NavLayout from "@/components/NavLayout";
import Table from "@/components/Table";
import { Link } from "react-router-dom";
import { useIntegrationCollection } from "@/hooks/integration";
import Spinner from "@/components/Spinner";
function IntegrationPage() {
  // const {users, isLoading} = useUserCollection();
  const { integrations, isLoading } = useIntegrationCollection();
  const [selected, setSelected] = useState<string[]>();
  const [current, setCurrent] = useState<string>();

  useEffect(() => {
    if (current != undefined) {
      const link = document.getElementById("my-link");
      if (link == null) {
        return;
      }
      link!.click();
    }
  }, [current]);

  const structuredIntegrations = integrations?.map((integration: any) => {
    return {
      id: integration.id,
      name: integration.name,
      active: integration.enabled,
      type: integration.type,
    };
  });

  useEffect(() => {
    console.log(integrations);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [integrations])

  if (isLoading) {
    return (
      <NavLayout>
        <div className="w-full h-full min-h-[500px] flex justify-center items-center">
          <Spinner/>
        </div>
      </NavLayout>
    );
  }

  return (
    <NavLayout>
      <div className="flex flex-col p-8 gap-y-4">
        <h1 className="text-2xl font-roboto">Integrations</h1>
        <Table
          dataSource={structuredIntegrations ?? []}
          mobileVisibleKeys={["name", "active"]}
          hidePrimaryKey={true}
          primaryKey="id"
          hideOptions
          hideCheckbox={false}
          setCheckboxSelected={setSelected}
          checkboxSelected={selected}
          onItemClick={(id: string) => {
            setCurrent(id);
          }}
        />
      </div>
      <Link
        id="my-link"
        className="hidden"
        to={
          (
            integrations?.filter(
              (integration) =>
                integration.id == current && integration.isSetup == true
            ) ?? []
          ).length > 0
            ? `${current}`
            : `${current}/setup`
        }
      ></Link>
    </NavLayout>
  );
}

export default IntegrationPage;

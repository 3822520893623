import { pb } from "@/lib/pocketbase";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const INTEGRATION_QUERY_KEY = 'integration';

export const getIntegrationCollection = () => {
  try {
    return pb.collection('integration').getFullList();
  } catch (err) {
    return undefined;
  }
};

export const updateIntegration = async (args:any) => {
  // console.log('args');
  // console.log(args);
  const data = {
    "settings": args.settings == undefined ? undefined : args.settings,
    "isSetup": args.isSetup == undefined ? undefined : args.isSetup
  };
  await pb.collection('integration').update(args.id, data);
}

export function useUpdateIntegrationCollection() {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (args: any) =>
      await updateIntegration(args),
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries([INTEGRATION_QUERY_KEY]);
      },
    },
  );
  return {
    updateIntegrationCollection: async (args: any) => {
      try {
        await mutation.mutateAsync(args);
        return true;
      } catch (err) {
        return false;
      }
    },
    updateIntegrationCollectionError: mutation.isError,
    updateIntegrationCollectionLoading: mutation.isLoading,
  };
}

export function useIntegrationCollection() {
  const { isLoading, isError, data, error } = useQuery(
    [INTEGRATION_QUERY_KEY],
    () => getIntegrationCollection(),
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      // staleTime: 30_000, // 3s
    },
  );

  return {
    integrations: data,
    isError,
    isLoading,
    error: error as Error,
  };
}
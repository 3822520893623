// colors.js
module.exports = {
  //Primatives
  transparent: "transparent",
  white: "#fff",
  black: "#000000",

  //Slate
  "slate-900": "#A6ADBA",
  "slate-800": "#9CA3B0",
  "slate-700": "#888888",
  "slate-600": "#707682",
  "slate-500": "#3d434f",
  "slate-400": "#3E444E",
  "slate-300": "#2a303c",
  "slate-200": "#383040",
  "slate-100": "#191d24",
  "destruction-light": "#EB4B63",
  "destruction-dark": "#EB4B63",
  "action-light": "#195FE6",
  "accept-dark": "#4A8A44",
  "accept-light": "#34C759",

  //Themes
  primary: "#8000FF",
  secondary: "#FF00C7",
  accent: "#c90000",
  neutral: "#A7ACB9",
  "base-100": "#151719",
  "base-content": "#fff",
  info: "#195FE6",
  success: "#34C759",
  warning: "#EB554B",
  error: "#EB4B63",
};

function SubmissionSettingsSection(this: any, { data }: { data: any }) {
  const POCKETBASE_URL = process.env.REACT_APP_API_URL || "";

  const handleDownload = async () => {
    try {
      const response = await fetch(
        `${POCKETBASE_URL}/api/files/${"submission"}/${data.id}/${data.file}`,
        {
          method: "GET",
        }
      );
      const blob = await response.blob();
      var url = URL.createObjectURL(blob!);
      console.log(url);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", data.file);
      tempLink.click();
    } catch (error) {
      //MARK: Insert Logging
      console.log(error);
    }
  };

  //object to array of arrays
  const structured = (() => {
    const dataEntriesArray = [];
    for (const [key, value] of Object.entries(data ?? {})) {
      dataEntriesArray.push({ key: key, value: JSON.stringify(value) });
    }
    return dataEntriesArray;
  })();

  return (
    <div className="pt-20 px-4 flex flex-col h-full w-full">
      <h1 className="text-2xl pb-2">{data?.name ?? ""}</h1>
      <p className="font-light text-sm pb-6">
        Please review the information about your submissions.
      </p>
      <form className="flex flex-col gap-y-4 h-full ">
        <div className="overflow-x-auto">
          <table className="table table-xs">
            {/* head */}
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {structured.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.key}</td>
                    <td>{x.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <button
          className="btn btn-info capitalize font-normal mt-auto"
          type="submit"
          onClick={handleDownload}
        >
          <p className="pr-2">Download</p>
        </button>
      </form>
    </div>
  );
}

export default SubmissionSettingsSection;

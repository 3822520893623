import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { PBUser } from '../../utils/types/types';
import { useQueryClient } from "@tanstack/react-query";
import { pb } from "@/lib/pocketbase";
// import { redirect_url } from '../../utils/env';
// import { login_url } from './../../utils/env';

interface RedirectProps {
  user?: any;
}

const RedirectPage: React.FC<RedirectProps> = ({ user }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const local_prov = JSON.parse(localStorage.getItem("provider") as string);
  const url = new URL(window.location.href);
  const code = url.searchParams.get("code") as string;
  const state = url.searchParams.get("state") as string;

  // this hasto match what you orovided in the oauth provider , in tis case google
  let redirectUrl = "http://localhost:3000/redirect";
  useEffect(() => {
    const pbOauthLogin = async () => {
      pb.autoCancellation(false);
      const oauthRes = await pb
        .collection("user")
        .authWithOAuth2(
          local_prov.name,
          code,
          local_prov.codeVerifier,
          redirectUrl
        );
      await pb.collection("user").update(oauthRes?.record.id as string, {
        avatar: oauthRes.meta?.avatarUrl,
        accessToken: oauthRes.meta?.accessToken,
      });
      queryClient.setQueryData(["user"], pb.authStore.model);
      navigate("/");
    };

    if (local_prov.state !== state) {
      const url = "http://localhost:3000/signup";
      if (typeof window !== "undefined") {
        window.location.href = url;
      }
    } else {
      pbOauthLogin().catch((e) => {
        console.log("error logging in with provider  == ", e);
      });
    }
  }, []);

  return (
    <div className="w-full h-full items-center justify-center">
      <span className="loading loading-bars loading-xs"></span>
    </div>
  );
};

export default RedirectPage;

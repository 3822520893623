import { useEffect, useState } from "react";
import NavLayout from "@/components/NavLayout";
import Button from "@/components/Button";
import Table from "@/components/Table";
import ReportManageSection from "@/sections/report/ReportManageSection";
import { useReportCollection } from "@/hooks/report";
import Spinner from "@/components/Spinner";
function ReportPage() {
  const { reports, isLoading: reportLoading } = useReportCollection();

  const [selected, setSelected] = useState<string[]>();
  const [currentReport, setCurrentReport] = useState<string>();

  useEffect(() => {
    console.log("currentReport");
    console.log(currentReport);

    console.log("(reports ?? []).filter(x => x.id == current)[0]");
    console.log((reports ?? []).filter((x) => x.id == currentReport)[0]);
  }, [currentReport]);

  const structured = JSON.parse(
    JSON.stringify(
      (reports ?? []).map((report: any) => {
        return {
          id: report.id,
          employeeKey: report.integrationDataKey ?? "",
          integration: report.expand.integrationId?.name ?? "",
          created: new Date(report.created).toLocaleString(),
          message: report.message ?? "",
        };
      })
    )
  );

  if (reportLoading) {
    return (
      <NavLayout>
        <div className="w-full h-full min-h-[500px] flex justify-center items-center">
          <Spinner/>
        </div>
      </NavLayout>
    );
  }

  return (
    <NavLayout
      overlayChildren={
        currentReport == undefined ? (
          <></>
        ) : (
          <ReportManageSection
            report={(reports ?? []).filter((x) => x.id == currentReport)[0]}
          />
        )
      }
    >
      <div className="flex gap-y-4 flex-col p-8">
        <h1 className="text-2xl font-roboto ">Reports</h1>
        <p>
          Here is a log of all the forms processed by Sign365 unprocessed forms
          will not appear here.
        </p>
        <Table
          dataSource={structured ?? []}
          mobileVisibleKeys={["employeeKey", "integration", "created", "message"]}
          hidePrimaryKey={true}
          primaryKey="id"
          hideOptions
          hideCheckbox={true}
          setCheckboxSelected={setSelected}
          checkboxSelected={selected}
          onItemClick={(id: string) => {
            setCurrentReport(id);
          }}
        />
        {/* <Button className='mt-10' appearance='accent'>Download CSV</Button> */}
      </div>
      <label id="my-link" htmlFor="my-drawer-4" className="drawer-button">
        <Button
          className="w-24 text-2xs md:text-xs md:w-40 pointer-events-none hidden"
          appearance="additive"
        >
          Add User
        </Button>
      </label>
    </NavLayout>
  );
}

export default ReportPage;

import { ButtonAppearance } from "./types";
export default function styled(appearance?:string): string{
	switch (appearance) {
		case ButtonAppearance.primary:
			return '';
		case ButtonAppearance.secondary:
			return '';
		case ButtonAppearance.additive:
			return 'text-base-content btn-success';
		case ButtonAppearance.destructive:
			return 'bg-destruction-light text-base-content btn-error';
		case ButtonAppearance.accent:
			return 'bg-action-dark text-base-content btn-info';
		default:
			return '';
	}
}
import React, { ReactNode } from "react";

export enum ButtonAppearance {
	primary = 'primary',
	secondary = 'secondary',
	destructive = 'destructive',
	additive = 'additive',
	accent = 'accent',
}

export interface Button extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children?: ReactNode;
	appearance?: keyof typeof ButtonAppearance;
	className?: string;
}
import { ReactNode } from 'react';

interface GridProps {
    children: ReactNode[] | ReactNode,
  }

function Grid({children}: GridProps) {
  return (
    <div className="gap-2 mt-3">
      <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-flow-row w-full h-full p-2 gap-2">
        {children}
      </div>
    </div>
  );    
};

export default Grid;

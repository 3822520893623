import NavLayout from "@/components/NavLayout";
import React from "react";

function DocumentationPage() {
  return (
    <NavLayout>
      <div className="h-[92%] w-full p-5 px-6">
        <div className="h-full w-full bg-slate-200 rounded-lg p-8 overflow-y-scroll flex flex-col items-center justify-center">
          <p className="text-xl text-transparent bg-clip-text bg-gradient-to-r from-secondary to-primary uppercase tracking-widest font-light">
            Coming soon...
          </p>
        </div>
      </div>
    </NavLayout>
  );
}

export default DocumentationPage;

import { ReactComponent as Sign365Logo } from "../../assets/images/sign365logo.svg";
import Button from "@/components/Button";
import Input from "@/components/Input";
import useLoginPage from "./useLoginPage";
import { pb } from "@/lib/pocketbase";
import Spinner from "@/components/Spinner";

function LoginPage() {
  const {
    loginFailed,
    isRequesting,
    errors,
    register,
    handleSubmit,
    onSubmit,
  } = useLoginPage();

  return (
    <div className="min-h-screen bg-base-100">
      {/* Centered Items */}
      <div className="flex flex-col justify-center items-center">
        <Sign365Logo className="w-60 mt-[30%] sm:mt-[10%] md:mt-[10%] mb-12 sm:mb-2 md:mb-20" />
        <form
          className="flex flex-col w-full px-14 gap-y-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            type="text"
            name="username"
            label="Username"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Username is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
          <Input
            type="password"
            name="password"
            label="Password"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Password is required",
            }}
            required
          />
          <Button
            disabled={isRequesting}
            appearance={loginFailed ? "destructive" : undefined}
            className="text-xs w-full btn-info"
            type="submit"
          >
            LOGIN
          </Button>
        </form>
      </div>
      {loginFailed && (
        <div className="toast toast-start ">
          <div className="alert alert-error">
            <div>
              <span className="text-base-content">
                Incorrect Username or Password
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginPage;

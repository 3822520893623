import { ReactComponent as Sign365Logo } from "../../assets/images/sign365logo.svg";
import Button from "@/components/Button";
import useSignupPage from "./useSignupPage";
import { pb } from "@/lib/pocketbase";

function SignupPage() {
  const { loginFailed, isRequesting } = useSignupPage();

  const handleSignup = () => {
    pb.collection("user")
      .listAuthMethods()
      .then((x) => {
        localStorage.setItem("provider", JSON.stringify(x.authProviders[0]));
        console.log(
          x.authProviders[0].authUrl + "http://localhost:3000/redirect"
        );
        const url =
          x.authProviders[0].authUrl + "http://localhost:3000/redirect";
        if (typeof window !== "undefined") {
          window.location.href = url;
        }
      });
  };

  return (
    <div className="min-h-screen bg-base-100">
      {/* Centered Items */}
      <div className="flex flex-col justify-center items-center">
        <Sign365Logo className="w-60 mt-[30%] sm:mt-[10%] md:mt-[10%] mb-12 sm:mb-2 md:mb-20" />
        <Button
          onClick={handleSignup}
          disabled={isRequesting}
          appearance={loginFailed ? "destructive" : undefined}
          className="text-xs w-full"
        >
          LOGIN
        </Button>
      </div>
      {loginFailed && (
        <div className="toast toast-start ">
          <div className="alert alert-error">
            <div>
              <span className="text-base-content">
                Incorrect Username or Password
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignupPage;

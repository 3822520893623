/* eslint-disable jsx-a11y/anchor-is-valid */
interface WarningComponentProps {
    warningText: string
}

function WarningComponent(props: WarningComponentProps) {
    return (
        <div className="flex flex-col justify-center bg-gradient-to-r to-warning from-error rounded-2xl w-full py-3 px-3">
            <p className="font-raleway font-extrabold text-base">
                {props.warningText}
            </p>

            <div className="flex items-start">
                <p className="font-arimo font-normal text-base">
                    Take a look at the cause of the error in the
                </p>

                <a className="font-arimo font-normal text-base text-blue-500 italic">
                    logs
                </a>
            </div>
        </div>
    )
}

export default WarningComponent
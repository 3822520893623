import {VictoryAxis, VictoryChart, VictoryLine, VictoryScatter, VictoryZoomContainer } from 'victory';

interface GraphicProps {
    dates: Date[],
    quant: number[],
}
  
function Graphic(props: GraphicProps) {
    const data = props.dates.map((item, index) => {return {x: item, y:props.quant[index]}})

    if (props.dates.length === 0) {
        return (
            <div className="flex flex-col justify-center items-center w-full min-h-full">
                <p className="font-raleway font-semibold text-[1.5rem] text-center">
                    Nothing To Report
                </p>

                <p className="font-arimo font-normal text-xl text-center">
                    There were no new submissions on the week
                </p>
            </div>
        )
    }
    
    else {
        return (
            <div className='w-full p-5'>
                <VictoryChart
                    domainPadding={20}
                    scale={{x: 'time'}}
                    containerComponent={<VictoryZoomContainer
                      />}
                    >
                    <VictoryAxis // X-Axis
                        style={{
                            axis: { stroke: '#fff' },
                            axisLabel: { fontSize: 12, padding: 30, fill: '#fff' },
                            ticks: { stroke: '#fff', size: 5 },
                            tickLabels: { fontSize: 10, padding: 5, fill: '#fff' },
                        }}
                        tickFormat={(x) => {
                            return `${(new Date(x)).getDate().toString().padStart(2, '0')}/${(new Date(x)).toLocaleString('default', { month: 'short' })}`;
                        }}
                    />
                    <VictoryAxis // Y-Axis
                        dependentAxis
                        style={{
                            axis: { stroke: '#fff' },
                            axisLabel: { fontSize: 12, padding: 30, fill: '#fff' },
                            ticks: { stroke: '#fff', size: 5 },
                            tickLabels: { fontSize: 10, padding: 5, fill: '#fff' },
                        }}
                        tickFormat={(t) => Number.isInteger(t) ? t : null}
                    />
    
                    <VictoryLine
                        style={{
                        data: { stroke: '#8000FF' },
                        }}
                        data={data}
                    />
                    <VictoryScatter
                        data={data}
                        size={3}
                        style={{ data: { fill: "#8000FF" } }} 
                    />
                </VictoryChart>
            </div>
        )
    } 
  }
  
export default Graphic;
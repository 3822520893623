import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

const useSignupPage = () => {
	const navigate = useNavigate();
	const [loginFailed, setLoginFailed] = useState(false);
	const [isRequesting, setIsRequesting] = useState(false);
	const {
	  register,
	  handleSubmit,
	  reset,
	  formState: { errors },
	} = useForm();

	const login = async (username:string, password:string) => {
		// await pb.collection('user').authWithOAuth2();
	}

	const onSubmit = async (data:any) => {
		setLoginFailed(false)
		setIsRequesting(true)
		
		try {
			await login(data.username, data.password);
			navigate('/');
		} catch (error) {
			setLoginFailed(true)
		}
		
		setIsRequesting(false)
		reset();
	};

	return {
		loginFailed,
		isRequesting,
		errors,
		register,
		handleSubmit,
		onSubmit
	}

}

export default useSignupPage